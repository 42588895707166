<template>
  <div>
    <div class="rooms bg-white FoundersGrotesk">
      <section class="container-xl">
        <div v-for="(rowData, index) in dataRows" :key="index" class="h-100">
          <b-row class="rooms-row">
            <b-col class="m-0 p-0" cols="12" md="7">
              <div class="lg-text text-uppercase">Rooms + Book</div>
            </b-col>
            <b-col cols="12" md="5" class="m-0 p-0">
              <div
                v-html="formatText(rowData.text)"
                class="md-text fade-in-up-element pb-4"
              ></div>
              <a :href="rowData.link" class="link-text">{{
                rowData.linkText
              }}</a>
            </b-col>
          </b-row>
        </div>
      </section>

      <div class="w-100 d-flex align-items-center justify-content-center p-0">
        <div class="outer-container">
          <VueSlickCarousel v-bind="settings">
            <div
              v-for="(image, index) in slickImages"
              :key="index"
              class="slider-container"
            >
              <div class="image-container pt-5">
                <img
                  :src="image.url"
                  :alt="image.title"
                  class="d-block mx-auto img-fluid"
                />
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
      showMenu: false,
      settings: {
        arrows: true,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      dataRows: [
        {
          text: "Each room in Ode has its own thing going for it: its own theme, its own furniture, and its own custom art, all commissioned from local artists, designers and manufacturers.",
          link: "/rooms",
          linkText: "BROWSE ROOMS >",
        },
      ],
      slickImages: [
        {
          url: "/img/home/Modern.jpg",
          title: "Ode Toronto - Grey",
        },
        {
          url: "/img/home/room-6.jpg",
          title: "Ode Toronto - Green",
        },
        {
          url: "/img/home/Room4-8.jpg",
          title: "Ode Toronto - Gold",
        },
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.rooms {
  padding: 50px 0px 50px 0px;
  .rooms-row {
    @media screen and (max-width: 1176px) {
      padding: 40px 40px 0px 40px;
    }
  }
  .link-text {
    letter-spacing: 1px;
  }
  .outer-container {
    width: 100%;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-black;
    text-decoration: none;
  }
  .slider-container {
    position: relative;
  }
  .slider-container img {
    max-height: 85vh;
  }

  img {
    width: 100vw;
  }

  .image-container {
    position: relative;
    text-align: center;
  }
}
</style>
